<template>
      <div class="neighbourhood-page">
            <Neighbourhood/>
      </div>
</template>

<script>
      import Neighbourhood from '@cp/neighbourhood/Neighbourhood'
      export default {
            name: 'neighbourhood-page',
            components: {Neighbourhood}
      }
</script>

<style lang='scss'>
.neighbourhood-page {
      width: 100%;
      height: calc(100% - 89px);
}
</style>