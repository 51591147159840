<template>
      <div class="neighbourhood full-width full-height">
            <Map v-if="!geojsonLoading" :geojson="geojson"/>
            <div v-show="!showFilters" @click="showFilters = true" class="filters-icon"></div>
            <Filters @close="close" :showFilters="showFilters"/>
      </div>
</template>

<script>
import Map from "@b/map/Map";
import Filters from './NeighbourhoodFilters.vue'
// import {geojson} from './geojson'
import { bus } from '@m'
import { getMapInfo } from "@api/axios/map.service";

export default {
      name: "neighbourhood",
      components: { Map, Filters },
      data(){
            return {
                  geojson: {
                        type: "FeatureCollection",
                        features: [
                        ],
                  },
                  showFilters: false,
                  selectedCategory: '',
                  geojsonLoading: true
            }
      },
      methods: {
            async getMapInfo(){
                  getMapInfo().then((res) => {
                        this.geojson.features = res.data.data;
                        this.geojsonLoading = false;
                  }).catch(e => {
                        console.log(e)
                  })
            },
            close(){
                  this.showFilters = false;
            },
            showFilter(id){
                  if(!this.showFilters) this.showFilters = true
                  bus.$emit('updateCardStyle', id);
            }
      },
      mounted(){
            this.getMapInfo();
            bus.$on('showFilter', this.showFilter);
      }
};
</script>

<style lang='scss'>
@import 'Neighbourhood';
</style>