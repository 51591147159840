<template>
      <div id="mapContainer" class="basemap"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { bus } from '@m';
// import {isEmpty} from 'lodash';
// import isEqual from 'lodash/isEqual'

export default {
      name: "BaseMap",
      props: {
            geojson: {
                  type: Object,
                  default: ()=>{}
            }
      },
      data() {
            return {
                  accessToken: "pk.eyJ1IjoiYWxpemFuZGQiLCJhIjoiY2tlbzI0cWRxMjNqdzJzcGN0NXpvbDhieiJ9.UUMfcAPNPe2wHEdkYMDEeQ"
            };
      },
      methods: {
            initMap() {
                  this.setMapAccessToken();
                  this.createMapInstance();
            },
            // Set Token For MapBox
            setMapAccessToken() {
                  mapboxgl.accessToken = this.accessToken;
            },
            getSiblings(el){
                  // for collecting siblings
                  let siblings = []; 
                  // if no parent, return no sibling
                  if(!el.parentNode) {
                        return siblings;
                  }
                  // first child of the parent node
                  let sibling  = el.parentNode.firstChild;
                  
                  // collecting siblings
                  while (sibling) {
                        if (sibling.nodeType === 1 && sibling !== el) {
                              if(sibling.classList.value.includes('marker') && !sibling.classList.value.includes('center-marker')) siblings.push(sibling);
                        }
                        sibling = sibling.nextSibling;
                  }
                  siblings.forEach(function(item){
                        if(item.classList.value.includes('selected-marker')) item.classList.remove("selected-marker")
                  });
                  return
            },
            // Create Map Instance
            createMapInstance(_geojson) {
                  var geojson = this.geojson;
                  const centeredMarker = geojson.features.find(item => item.properties.center);
                  const mapInfo = {
                        container: "mapContainer",
                        style: "mapbox://styles/mapbox/light-v9",
                        zoom: 12,
                        center : centeredMarker.geometry.coordinates
                        // pitch: 45,
                        // bearing: -17.6
                  }
                  var map = new mapboxgl.Map(mapInfo);
                  function doIt(c){
                        let tags = document.querySelectorAll('.marker');
                        tags.forEach(function(item){
                              if(item.querySelector('.marker-title') && Number(item.querySelector('.marker-title').innerHTML) === c.info.properties.id) {
                                    item.classList.add("selected-marker")
                              } else {
                                    item.classList.remove("selected-marker")
                              }
                        })
                        map.flyTo({
                              center: c.info.geometry.coordinates,
                              zoom: 15
                        })
                  }

                  var hasCategory;
                  function filterByCat(name){
                        if(name === 'All') {
                              document.querySelectorAll('.marker-title').forEach(function(item){
                                    console.log(item.parentElement.classList)
                                    if(item.parentElement.classList.value.includes('selected-marker')) item.parentElement.classList.remove('selected-marker');
                                    if(item.parentElement.classList.value.includes('hidden')) item.parentElement.classList.remove('hidden');
                                    let bounds = new mapboxgl.LngLatBounds();
                                    geojson.features.forEach(function (feature) {
                                          bounds.extend(feature.geometry.coordinates);
                                    });
                                    map.fitBounds(bounds, { padding: 80 });
                                    return
                              })
                        }
                        const arr = geojson.features.filter(function(item){return item.properties.category.name === name});
                        if(!arr.length) return
                        const numbers = arr.map(function(i){return i.properties.id});
                        const tags = document.querySelectorAll('.marker-title');
                        tags.forEach(function(item){
                              if(numbers.includes(parseInt(item.innerHTML))) {
                                    item.parentElement.classList.remove('hidden')
                              } else {
                                    item.parentElement.classList.add('hidden')
                              }
                        })
                        let bounds = new mapboxgl.LngLatBounds();
                        geojson.features.forEach(function (feature) {
                              bounds.extend(feature.geometry.coordinates);
                        });
                        map.fitBounds(bounds, { padding: 80 });
                        // map.flyTo({
                        //       center: arr[Math.floor(numbers.length/2)].geometry.coordinates
                        // })
                  }
                  bus.$on('updateMarkers', filterByCat)
                  bus.$on('handleCardInfoClick', doIt)

                  for (const feature of _geojson?.length? _geojson : this.geojson.features) {
                        // create a HTML element for each feature
                        if(hasCategory && ((feature.properties.category.name !== hasCategory) && !feature.properties.center)) continue
                        const el = document.createElement("div");
                        el.addEventListener('click', () => {
                              if(el.classList.value.includes('center-marker')) return
                              this.getSiblings(el);
                              el.classList.add('selected-marker');
                              bus.$emit('showFilter', feature.properties.id);
                              map.flyTo({
                                    center: feature.geometry.coordinates,
                                    zoom: 15
                              })
                        });

                        el.className = "marker";

                        if (feature.properties.center) {
                              el.classList.add("center-marker");
                        } else {
                              el.innerHTML += `<span class="marker-title">${feature.properties.id}</span>`;
                        }

                        // Make a marker for each feature and add to the map
                        new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates).addTo(map);
                  }
                  var bounds = new mapboxgl.LngLatBounds();
                  this.geojson.features.forEach(function (feature) {
                        bounds.extend(feature.geometry.coordinates);
                  });
                  map.fitBounds(bounds, { padding: 80 });
            },
            zoomToCoordinate(){
                  return
                  // this.createMapInstance(null, item)
            }
      },
      created(){
            bus.$on('updateMarker', this.createMapInstance)
            // bus.$on('handleCardInfoClick', this.zoomToCoordinate)
      },
      mounted() {
            this.$nextTick(()=> {
                  this.initMap();
            })
      },
      beforeDestroy(){
            this.$el.removeEventListener('click', () => {
                  
            })
      }
};
</script>

<style lang="scss">
.basemap {
      width: 100%;
      height: 100%;
}

.marker {
      background-image: url("../../../assets/icons/svg/custom-location.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 45px;
      height: 54px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
}

.center-marker {
      background-image: url("../../../assets/icons/svg/descriptions.svg");
      background-size: 115px;
      width: 156px;
      height: 108px;
      z-index: 15;
}

.marker-title {
      @include toRem(font-size, 18);
      color: $white;
      margin-bottom: 9px;
}

.selected-marker {
      background-image: url("../../../assets/icons/svg/selected-marker.svg") !important;
      z-index: 10;
}

.hidden {
      visibility: hidden;
}
</style>