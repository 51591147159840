<template>
      <div :id="`card-${item.info.properties.id}`" @click="handleCardInfoClick" class="info-card cursor-pointer" :class="{'bg-light': selectedId === item.info.properties.id}">
            <p class="marker-name">{{item.info.properties.id}}. {{item.info.properties.name}}</p>
            <div class="d-flex full-width justify-content-between mt-5">
                  <span class="category">{{item.info.properties.category.name}}</span>
                  <div class="duration d-flex"> 
                        <p class="mr-15">
                              <img src="@/assets/icons/svg/car.svg" class="filter invert-100" alt="arrow icon" draggable="false">
                              <span class="ml-15 car-time">{{item.info.properties.car_time}} min</span>
                        </p>
                        |
                        <p class="ml-15">
                              <img src="@/assets/icons/svg/walk.svg" class="filter invert-100" alt="arrow icon" draggable="false">
                              <span class="ml-15 walk-time">{{item.info.properties.walking_time}} min</span>
                        </p>
                  </div>
            </div>
      </div>
</template>

<script>
      import { bus } from '@m'
      export default {
            name: 'neightbourhood-filters-info-card',
            props: {
                  item: {
                        type: Object,
                        default: ()=>{}
                  }
            },
            data(){
                  return {
                        selectedId: null
                  }
            },
            methods :{
                  handleCardInfoClick(){
                        const id = this.item.info.properties.id
                        let tag = document.getElementById(`card-${id}`);
                              // for collecting siblings
                              let siblings = []; 
                              // if no parent, return no sibling
                              if(!tag.parentNode) {
                                    return siblings;
                              }
                              // first child of the parent node
                              let sibling  = tag.parentNode.firstChild;
                              
                              // collecting siblings
                              while (sibling) {
                                    if (sibling.nodeType === 1 && sibling !== tag) {
                                          if(sibling.classList.value.includes('info-card')) siblings.push(sibling);
                                    }
                                    sibling = sibling.nextSibling;
                              }
                              siblings.forEach(function(item){
                                    if(item.classList.value.includes('bg-light')) item.classList.remove("bg-light")
                              });
                              document.getElementById(`card-${id}`).classList.add('bg-light')
                        // document.getElementById(`card-${this.item.info.properties.id}`).classList.add('bg-light')
                        bus.$emit('handleCardInfoClick', this.item)
                        // bus.$emit('closeLegend')
                  }
            }
      }
</script>

<style lang='scss'>
.bg-light {
      background: #e5e5cf;
}
.info-card {
      padding: 22px;
      box-shadow: 0px 6px 16px #13131315;
      border-radius: 23px;
      margin-bottom: 24px;
      font-family: $vaud-normal;

      .marker-name {
            font-family: $vaud-bold;
            @include toRem(font-size, 18);
      }

      .category {
            @include toRem(font-size, 14);
            color: #131313;
            opacity: .4;
            white-space: nowrap;
            font-family: 'Vaud';
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: $vaud-normal;
            p {
                  font-family: $vaud-normal;

                  span {
                        font-family: $vaud-normal;
                  }
            }
      }

      .duration {
            @include toRem(font-size, 14);
            font-family: $vaud-normal;

            .car-time{
                  font-family: $vaud-normal;
            }

            .walk-time{
                  font-family: $vaud-normal;
            }
      }

      &:first-child {
            margin-top: 50px;
      }
      &:last-child {
            margin-bottom: 5px;
      }
}
</style>