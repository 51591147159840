<template>
      <div class="neighbourhood-filters" id="neighbourhood-filter" :class="[showFilters ? 'show': 'hide']">
            <div class="filters-header">
                  <Btn id="close-modal" class="close" size="sm" variant="gray" icon="arrow-left-filters" @click.native="$emit('close')"/>
                  <span class="filters__title">Neighbourhood</span>
                  <Btn id="close-modal" class="filters" size="sm" variant="gray" icon="filters" @click="showFilterBar = true"/>
            </div>
            <div v-if="filteredMarkerInfo.length" class="markerInfoWrapper">
                  <InfoCard v-for="(info, index) in filteredMarkerInfo" :item="{info, index}" :key="generateUniqueId(index + 5)"/>
            </div>
            <div class="not-found" v-else>
                  No Location Found!
            </div>
            <div v-show="showFilterBar" class="filter-menu full-width full-height" @click="closeMenu($event)">
                  <FilterMenu @filterByCategory="filterByCategory" :filterItems="filterItems"/>
            </div>
      </div>
</template>

<script>
      import InfoCard from './NeightbourhoodFiltersInfoCard.vue';
      import FilterMenu from './NeighbourhoodFilterMenu.vue';
      import Btn from '@b/button/Button';
      import { getMapInfo } from "@api/axios/map.service";
      import {generateUniqueId} from '@/helpers/helpers';
      import { getCategoryList } from "@api/axios/neighbourhood.service";
      import { bus } from '@m'

      export default {
            name: 'neighbourhood-filters',
            components: {Btn, InfoCard, FilterMenu},
            data(){
                  return {
                        markerInfo: [],
                        showFilterBar: false,
                        selectedCategory: '',
                        filterItems: []
                  }
            },
            props : {
                  showFilters: {
                        type: Boolean,
                        default: false
                  }
            },
            computed: {
                  filteredMarkerInfo(){
                        var items = [];
                        if(this.selectedCategory && this.selectedCategory !== 'All') {
                              const vm = this;
                              items =  this.markerInfo.filter(function (item){
                                    return item.properties.center || item.properties.category.name === vm.selectedCategory
                              })
                        } else {
                              items = this.markerInfo
                        }
                        return items.filter(function(item){return !item.properties.center})
                  }
            },
            methods: {
                  generateUniqueId,
                  closeMenu(e){
                        if(e.target.className.includes('filter-menu')) this.showFilterBar = false;
                  },
                  filterByCategory(item){
                        this.showFilterBar = false;
                        this.selectedCategory = item.name;
                        bus.$emit('updateMarkers', this.selectedCategory)
                  },
                  getCategoryList(){
                        getCategoryList().then((res)=> {
                              this.filterItems = res.data.data;
                        }).catch((e)=> {
                              console.log(e)
                        });
                  },
                  async getMarkerInfo(){
                        getMapInfo().then((res)=> {
                              // this.markerInfo = res.data.data;
                              // res.data.data[0].properties.center = true;
                              this.markerInfo = res.data.data.sort(function(a, b) {
                                    return a.properties.id - b.properties.id > 0 ? 1 : -1
                              });
                        }).catch((e)=> {
                              console.log(e)
                        });
                        // const res = await getMapInfo();
                        // this.markerInfo = res.data.data;
                  },
                  updateCardStyles(id){
                        // console.log('id', id)
                        setTimeout(() => {
                              let tag = document.getElementById(`card-${id}`);
                              // for collecting siblings
                              let siblings = []; 
                              // if no parent, return no sibling
                              if(!tag.parentNode) {
                                    return siblings;
                              }
                              // first child of the parent node
                              let sibling  = tag.parentNode.firstChild;
                              
                              // collecting siblings
                              while (sibling) {
                                    if (sibling.nodeType === 1 && sibling !== tag) {
                                          if(sibling.classList.value.includes('info-card')) siblings.push(sibling);
                                    }
                                    sibling = sibling.nextSibling;
                              }
                              siblings.forEach(function(item){
                                    if(item.classList.value.includes('bg-light')) item.classList.remove("bg-light")
                              });
                              document.getElementById(`card-${id}`).classList.add('bg-light')
                        }, 1);
                        this.scrollToId(id)

                  },
                  scrollToId(id){
                        // await document.getElementById(`card-${id}`).scrollIntoView({
                        //       behavior: 'smooth'
                        // });
                        document.getElementById('neighbourhood-filter').scrollTop = document.getElementById(`card-${id}`).offsetTop - 89;
                  }
            },
            mounted(){
                  bus.$on('updateCardStyle', this.updateCardStyles)
                  this.getMarkerInfo();
                  this.getCategoryList();
            }
      }
</script>

<style lang='scss'>
.neighbourhood-filters {
      height: 100%;
      width: 25%;
      background: #fff;
      position: absolute;
      z-index: 200;
      top: 0;
      overflow-y: scroll;

      @media screen and (max-width:768px) {
            width: 85%;
      }

      &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            display: block;
            margin-top: 20px;
            scroll-margin: 50px 0 0 50px;
      }

      &::-webkit-scrollbar-thumb {
            border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 5px;
      }
      

      .filters-header {
            position: sticky;
            top: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            height: 86px;
            padding: 25px;
            z-index: 99;
            background-color: #fff;
            // .close, .filters__title, .filters {
            // }

            .close {
                  width: 60px;
                  height: 60px;
                  z-index: 1;

                  @media screen and (max-width: 1366px){
                        padding: 10px;
                        width: 45px;
                        height: 45px;
                        border-radius: 16px !important;
                  }

                  .wrapper {
                        display: flex;
                        justify-content: center;

                        span {
                              display: none;
                        }
                  }
            }

            .filters__title {
                  font: 16px $vaud-normal;
                  left: 10%;
                  transition: left .9s;

            }

            .filters {
                  width: 60px;
                  height: 60px;
                  transition: left .9s;

                  @media screen and (max-width: 1366px){
                        padding: 10px;
                        width: 45px;
                        height: 45px;
                        border-radius: 16px !important;
                  }

                  .wrapper {
                        display: flex;
                        justify-content: center;

                        span {
                              display: none;
                        }
                  }
                  box-shadow: 0px 3px 6px #00000029;
            }
      }

      .markerInfoWrapper{
            padding: 0 25px 25px 25px;
      }

      .filter-menu {
            position: absolute;
            z-index: 270;
            top: 0;
            left: 0;
            background: transparent;
      }

      .not-found {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            font-size: larger;
            border-radius: 15px;
      }

}

.bg-light {
      background: #e5e5cf;
}

.show {
      left: 0;
      transition: left .9s;
}

.hide {
      left: -40%;
      @media screen and (max-width:768px) {
            left: -85%;
      }
      transition: left .9s;

      .close, .filters__title, .filters {
            display: none;
      }

}
</style>