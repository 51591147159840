<template>
      <div class="neighbourhood-filter-menu">
            <p @click="$emit('filterByCategory', item)" class="filter__item" v-for="(item, index) in [{name: 'All', icon: ''},...filterItems]" :key="generateUniqueId(index + 5)">
                  <img :src="item.icon || require(`../../../assets/icons/svg/filters.svg`)" class="filter invert-100" alt="icon" draggable="false">
                  <span class="state ml-15">{{item.name}}</span>
            </p>
      </div>
</template>

<script>      
      import { generateUniqueId } from '@/helpers/helpers';
      export default {
            name: 'neighbourhood-filter-menu',
            props: {
                  filterItems: {
                        type: Array,
                        default: () => []
                  }
            },
            methods: {generateUniqueId}
      }
</script>

<style lang='scss'>
.neighbourhood-filter-menu {
      position: fixed;
      top: 130px;
      left: 180px;
      width: 13%;
      background: #ffffff;
      box-shadow: 0px 12px 36px #13131366;
      border-radius: 14px;
      padding: 26px;
      z-index: 300;
      transition: all ease .6s;

      @media screen and (max-width: 768px){
            width: 70%;
      }

      &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            display: block;
            margin-top: 20px;
            scroll-margin: 50px 0 0 50px;
      }

      &::-webkit-scrollbar-thumb {
            border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 5px;
      }

      .filter__item {
            border-bottom: 1px solid rgba($black, .1);
            padding-bottom: 14px;
            padding-top: 14px;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
                  width: 10%;
            }

            &:first-child {
                  padding-top: 0;
            }
            &:last-child {
                  border: none;
                  padding-bottom: 0;
                  // margin-bottom: 5px;
            }

            &:hover {
                  transform: scale(1.025);
                  transition: .5s;
            }
            .state {
                  @include toRem(font-size, 16);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-family: 'Vaud';
            }
      }
}
</style>